import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Breadcrumbs from "../components/breadcrumbs";
import ReactMarkdownWithHtml from "react-markdown"
import { GatsbyImage } from "gatsby-plugin-image/dist/src/components/gatsby-image.browser";

function PageTemplate({ pageContext: { page } }) {
    const ldJson = [];
    const breadcrumbs = [];
    breadcrumbs.push({ name: page.Title, link: `/${page.Slug}` });

    const breadcrumbsLd = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": breadcrumbs.map((item, index) => {
            return {
                "@type": "ListItem",
                "position": index + 1,
                "name": item.name,
                "item": `https://liderancaegestao.online${item.link}`
            }
        })
    };
    ldJson.push(breadcrumbsLd);

    return (
        <Layout>
            <SEO title={page.Title} ldJson={ldJson} description={page.MetaDescription} keywords={page.MetaKeywords} />
            <Breadcrumbs items={breadcrumbs} />
            <h1>{page.Title}</h1>
            <img src={`https://cms.wesolveit.com.br/assets/${page.CoverImage.filename_disk}`} alt={page.CoverImage.title} />
            <div className="flex flex-col md:flex-row pb-14">
                <div className="w-full md:w-12/12 mt-4 md:mt-0 md:ml-1">
                    <p className="leading-normal pt-2">
                        <ReactMarkdownWithHtml children={page.Description} />
                    </p>
                </div>
            </div>
        </Layout>
    )
}

export default PageTemplate